import React from 'react';
import ImgSquiggleLeft from '../../images/main/squiggle-left.png';
import ImgSquiggleRight from '../../images/main/squiggle-right.png';

// eslint-disable-next-line react/prop-types
const Squiggles = ({ children }) => (
  <div className="squiggles">
    <div className="sq-images">
      <img src={ImgSquiggleLeft} className="sqi-left" alt="squiggle" />
      <img src={ImgSquiggleRight} className="sqi-right" alt="squiggle" />
    </div>
    {children}
  </div>
);

export default Squiggles;
